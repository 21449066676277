/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
    margin:0
}

.logo-container img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0; /* Ajusta el espaciado alrededor del logo según sea necesario */
  }

  .logout-button-container {
    position: absolute!important;
    bottom: 80px;
  }


  /* Estilos para el logo cuando el Sider no está colapsado */
.logo {
    overflow: hidden;
    transition: width 0.1s ease; /* Opcional: añade una transición suave */
    padding: 16px;
  }
  
  /* Estilos para el logo cuando el Sider está colapsado */
  .logo.collapsed {
    width: 49px;
    padding-left: 25px;
    overflow: hidden;
    transition: width 0s ease;
  }
  .logo.collapsed img {
    width: 153px; 
    max-width: 153px!important;
  }
  

