.tenant-bolsas-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .header {
    _position: fixed;
    _width: 50%;
    _height: 260px;
    _z-index: 1;
    _background-color: white;
    _padding: 16px;
    _box-shadow: 0 2px 8px #f0f1f2;
  }

  .content {
    flex: 1;
    overflow: auto;
    _margin-top: 264px; /* Ajusta esto según el tamaño de tu encabezado */
    margin-bottom: 40px;
  }
  
  .charts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    height: 273px;
  }
  
  .charts > * {
    flex: 1;
    margin-right: 8px;
  }
  
  .charts > *:last-child {
    margin-right: 0;
  }
  
  .tenant-bolsas-container .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 9;
  }