
  .btn-nav {
    @apply bg-blue-500 text-white py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:bg-blue-600 hover:-translate-y-1 hover:shadow-lg;
  }
  .react-quill {
    background-color: white; /* Asegurándose de que el fondo sea blanco */
  }

  .react-quill .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #e5e7eb;
  }

  .react-quill .ql-container.ql-snow {
    border: none;
  }


  .custom-disabled-datepicker {
    background-color: #fff!important;
  }
  .custom-disabled-datepicker input {
    color:  rgba(0, 0, 0, 0.88)!important;
  }

  .custom-checkbox-with-label>span:first-child  {
    align-self: flex-start;
    margin-top: 3px;
  }


  .modalHeaderMargin{
    margin-top: 30px;
  }

.modalEmail label {
  width: 70px;
  justify-content: flex-end;
}
.modalEmail .ant-form-item {
  margin-bottom: 10px;
}




/* custom-quill.css */

/* Ajusta el margen y el padding de los párrafos */
.ql-editor p {
  margin: 0 0 0!important; /* Ajusta el margen inferior según tus necesidades */

}
.ql-editor div {
  margin: 10; /* Ajusta el margen inferior según tus necesidades */
  padding: 0;
}

/* Ajusta el tamaño y el estilo de los encabezados */
.ql-editor h1 {
  font-size: 24px;
  margin-bottom: 15px;
}

.ql-editor h2 {
  font-size: 20px;
  margin-bottom: 12px;
}

/* Ajusta los estilos de la lista */
.ql-editor ul, .ql-editor ol {
  margin: 10px 0 10px!important;;
  padding-left: 20px!important;;
}

/* Ajusta los estilos del enlace */
.ql-editor a {
  color: #007bff; /* Cambia el color según tu diseño */
  text-decoration: none;
}

.ql-editor a:hover {
  text-decoration: underline;
}
/* custom-quill.css */

/* Ajusta los estilos generales del editor para parecerse a Gmail */
.ql-editor {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  padding: 20px; /* Ajusta el padding según sea necesario */
}

/* Ajusta los estilos de los encabezados */
.ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  margin-top: 0;
  margin-bottom: 10px;
}



/* tabla de tags */
.cfa_table_tags .ant-table-cell {
  padding: 5px!important;
}
.cfa_table_tags .ant-table-tbody > tr > td {
  position: relative;
}

.cfa_table_tags .anticon-copy {
  visibility: hidden;
  margin-left: 8px;
}

.cfa_table_tags .ant-table-tbody > tr:hover .anticon-copy  {
  visibility: visible;
  color: grey
}
